import React, { useRef, useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { AnimatePresence, m } from "framer-motion";
import useSolutionsData from "@staticQueries/SolutionsQuery";
import useModulesData from "@staticQueries/ModulesQuery";
import useWindowSize from "@hooks/useWindowSize";
import loadable from "@loadable/component";
import { ModuleChip } from "@molecules";
import { Container, ButtonAlt, Button, Text } from "@atoms";

import { useAppState } from "@state";

const SectionHeading = loadable(() => import("@organisms/SectionHeading"));

const SolutionBuilder = ({
  backLink,
  heading,
  descriptor,
  helper,
  cta,
  button,
}) => {
  const _solutions = useSolutionsData();
  const _modules = useModulesData();

  const modules = useMemo(
    () =>
      _modules
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(mod => ({ ...mod })),
    []
  );

  const [{ solution }, dispatch] = useAppState();

  const solutions = _solutions
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((s, i) => ({
      ...s,
      modules: modules.filter(mod => s.moduleList.includes(mod.uid)),
    }));

  const [active, setActive] = useState(null);
  const [radius, setRadius] = useState(0);
  const circle = useRef(null);

  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if (circle.current) {
      setRadius(circle.current.clientWidth / 2);
    }
  }, [innerWidth, circle.current, solution.solution]);

  useEffect(() => {
    // get solution from hash
    const { hash } = window.location;
    if (!hash) {
      dispatch({
        type: "clearSolution",
      });
    } else if (hash) {
      const s = solutions.find(sol => sol.slug === hash.replace("#", ""));
      if (s) {
        dispatch({
          type: "setSolution",
          solution: s.uid,
          modules: s.modules.map(mod => mod.uid),
        });
      }
    }
  }, []);

  const tooltips = {
    show: {
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    hide: {
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <section className="relative z-10 pt-10">
      <Container>
        <div className="mb-10 flex flex-col gap-10">
          {backLink?.url && (
            <ButtonAlt reverse to={backLink.url}>
              {backLink.text}
            </ButtonAlt>
          )}
          <SectionHeading heading={heading} descriptor={descriptor} />
        </div>

        <div className="flex w-full flex-col gap-10 md:flex-row md:items-start">
          <div className="order-2 w-full md:order-1 md:w-2/5 lg:flex-[1_0_50%]">
            <ul className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
              {modules.map(mod => (
                <li key={mod.uid}>
                  <ModuleChip
                    {...mod}
                    mode="add"
                    onClick={() => {
                      dispatch({
                        type: "toggleModule",
                        module: mod.uid,
                      });
                    }}
                    state={solution.modules.includes(mod.uid)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="top-0 order-1 mx-auto w-full max-w-md rounded bg-white p-10 shadow-lg sm:order-2 sm:p-20 md:sticky md:w-3/5 lg:flex-[0_1_50%]">
            <div className="relative flex aspect-square w-full items-center justify-center">
              <m.p
                initial={{ scale: 0 }}
                animate={solution.modules.length ? { scale: 0 } : { scale: 1 }}
                className="p-14 text-center text-sm leading-normal"
                transition={{ duration: 0.3 }}
              >
                {helper}
              </m.p>
              <ul
                ref={circle}
                className="absolute inset-0 flex items-center justify-center"
              >
                {/* the placeholders */}
                {[...modules].map((mod, i) => {
                  const xPos =
                    Math.cos(
                      ((i - modules.length / 4) / modules.length) * 2 * Math.PI
                    ) * radius;

                  const yPos =
                    Math.sin(
                      ((i - modules.length / 4) / modules.length) * 2 * Math.PI
                    ) * radius;

                  return (
                    <m.li
                      key={mod.uid}
                      className={classNames(
                        "pointer-events-none absolute inset-[calc(50%_-_1.5rem)] flex h-12 w-12 items-center justify-center rounded-full border-2 border-dashed border-teal",
                        { "opacity-0": i < solution.modules.length }
                      )}
                      animate={{
                        x: xPos,
                        y: yPos,
                      }}
                    />
                  );
                })}
                {/* the icons */}
                <AnimatePresence initial>
                  {[...solution.modules]
                    .map(mod => modules.find(_mod => mod === _mod.uid))
                    .map((mod, i) => {
                      const xPos =
                        Math.cos(
                          ((i - modules.length / 4) / modules.length) *
                            2 *
                            Math.PI
                        ) * radius;
                      const yPos =
                        Math.sin(
                          ((i - modules.length / 4) / modules.length) *
                            2 *
                            Math.PI
                        ) * radius;

                      return (
                        <>
                          <m.li
                            layout
                            key={mod.uid}
                            initial={{
                              scale: 0,
                              x: xPos,
                              y: yPos,
                            }}
                            exit={{ scale: 0 }}
                            animate={{
                              scale: 1,
                              x:
                                Math.cos(
                                  ((i - modules.length / 4) / modules.length) *
                                    2 *
                                    Math.PI
                                ) * radius,
                              y:
                                Math.sin(
                                  ((i - modules.length / 4) / modules.length) *
                                    2 *
                                    Math.PI
                                ) * radius,
                            }}
                            onHoverStart={() => setActive(i)}
                            onHoverEnd={() => setActive(null)}
                            onTap={() => setActive(active === i ? null : i)}
                            className={classNames(
                              "absolute inset-[calc(50%_-_1.5rem)] z-10 flex h-12 w-12 origin-center items-center justify-center rounded-full",
                              {
                                "bg-teal": mod.color === "teal",
                                "bg-purple": mod.color === "purple",
                                "bg-pink": mod.color === "pink",
                              }
                            )}
                          >
                            <m.div
                              initial={{ scale: 0 }}
                              animate={
                                solution?.modules?.includes(mod.uid)
                                  ? { scale: 1 }
                                  : { scale: 0 }
                              }
                              className="svg-icon--fit-height h-full w-full flex-shrink-0 p-[20%] text-white"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: mod?.icon?.[0]?.code,
                              }}
                            />
                          </m.li>
                          <m.div
                            initial="hide"
                            animate={active === i ? "show" : "hide"}
                            variants={tooltips}
                            className={classNames(
                              "absolute bottom-[calc(50%+2rem)] z-20 rounded-md bg-black p-2 text-white",
                              {
                                "pointer-events-none": active !== i,
                                "left-[calc(50%-1.5rem)]": i < 2 || i > 6,
                                "right-[calc(50%-1.5rem)]": i >= 2 && i <= 6,
                              }
                            )}
                            style={{ translateX: xPos, translateY: yPos }}
                          >
                            <div
                              className={classNames(
                                "absolute -bottom-2 h-0 w-0 border-l-[0.5rem] border-r-[0.5rem] border-t-[0.5rem] border-l-transparent border-r-transparent border-t-black",
                                {
                                  "left-4": i < 2 || i > 6,
                                  "right-4": i >= 2 && i <= 6,
                                }
                              )}
                            />
                            <Text variant="xs" className="whitespace-nowrap">
                              {mod.title}
                            </Text>
                          </m.div>
                        </>
                      );
                    })}
                </AnimatePresence>
              </ul>
            </div>
            <div className="mt-10 flex w-full flex-col items-center justify-center gap-5 text-center sm:mt-20">
              <Text variant="h3">{cta}</Text>
              {button?.url && <Button to={button.url}>{button.text}</Button>}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SolutionBuilder;
